<template>
  <v-dialog v-model="drawer" max-width="800" persistent scrollable>
    <v-form v-if="obModel" class="fill-height" @submit.prevent>
      <gw-form-observer
        :loading="isLoading"
        hide-top-actions
        lazy-validation
        @cancel="cancel"
        @save="save"
      >
        <settings v-if="drawer" v-model="obModel" @save="save" />
      </gw-form-observer>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import { Mixins, Component, PropSync } from "vue-property-decorator";
import ContractCategoriesMixin from "@/modules/contractcategories/mixins/ContractCategoriesMixin";
import Settings from "@/modules/contractcategories/components/tabs/Settings.vue";

@Component({
  components: {
    Settings,
  },
})
export default class ContractcategoriesForm extends Mixins(
  ContractCategoriesMixin
) {
  @PropSync("open", { type: Boolean, default: false }) drawer!: boolean;
}
</script>
