<template>
  <data-table
    :headers="headers"
    :items="obCollection.getModelList()"
    :options.sync="pagination"
    :total="serverItemsLength"
    :last-page="serverLastPage"
    :loading="bLocalLoading"
    btn-action-path="contractcategories"
    btn-action-item-key-id="id"
    hide-btn-action-view
    is-route-name
    @delete="deleteItem"
    @update:sort-by="onSortBy"
    @update:sort-desc="onSortDesc"
    @update:options="onPagination"
    @update:page="onPage"
  >
    <template #[`item.name`]="{ item }">
      <v-btn
        :to="{
          name: 'contractcategories.update',
          params: { id: item.id },
        }"
        text
        color="primary"
      >
        {{ item.name }}
      </v-btn>
    </template>

    <template #[`item.actions`]="{ item }">
      <module-actions :value="item.id" @delete="deleteItem" />
    </template>

    <template #top>
      <contract-categories-form
        v-model="obItem"
        :open.sync="displayForm"
        :key="$route.params.id"
      />
    </template>
  </data-table>
</template>

<script lang="ts">
import { Mixins, Component } from "vue-property-decorator";
import ContractCategoriesMixin from "@/modules/contractcategories/mixins/ContractCategoriesMixin";
import ContractCategoriesForm from "@/modules/contractcategories/components/Form.vue";
import type { DataTableHeader } from "@/mixins/DataTableMixin";

@Component({
  components: {
    ContractCategoriesForm,
  },
})
export default class ContractcategoriesList extends Mixins(
  ContractCategoriesMixin
) {
  isList = true;

  onMounted() {
    const arHeaders: DataTableHeader[] = [
      { text: "name", value: "name" },
      { text: "code", value: "code" },
    ];
    this.setDTHeaders(arHeaders);

    this.index();
  }
}
</script>
