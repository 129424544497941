<template>
  <v-row>
    <v-col cols="12" lg="6" xl="4">
      <form-field-text v-model="obContractCategory.name" @save="save" />
    </v-col>

    <v-col cols="12" xs="12" md="6" lg="3" xl="3">
      <code-field-text
        v-model="obContractCategory.code"
        item-key="contract_category"
        auto-assign
        required
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Vue, Component, VModel } from "vue-property-decorator";
import { ContractCategory } from "@planetadeleste/vue-mc-gw";
import CodeFieldText from "@/components/form/fields/CodeFieldText.vue";

@Component({
  components: { CodeFieldText },
})
export default class ContractcategoriesSettings extends Vue {
  @VModel({ type: Object, default: () => new ContractCategory() })
  obContractCategory!: ContractCategory;

  save() {
    this.$emit("save");
  }
}
</script>
