import { Component, Mixins } from "vue-property-decorator";
import ModelMixin from "@/mixins/ModelMixin";
import type { VueClass } from "vue-class-component/lib/declarations";
import {
  ContractCategory,
  ContractCategoryCollection,
} from "@planetadeleste/vue-mc-gw";

const TypedModelMixin = ModelMixin as unknown as VueClass<
  ModelMixin<ContractCategory, ContractCategoryCollection>
>;

@Component
export default class ContractCategoriesMixin extends Mixins(TypedModelMixin) {
  obCollection = new ContractCategoryCollection();
  obModelClass = ContractCategory;
  obCollectionClass = ContractCategoryCollection;
  sRoutePath = "/contractcategories";

  created() {
    this.onCreated();
  }
}
